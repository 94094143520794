#maintenance-message {
  font-size: 40px;
  color: white;
  margin: 2rem;
}

#maintenance-thanks {
  color: white;
}

#maintenance-container {
  background-color: #1b8474;
  text-align: center;
  font: 20px Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#maintenance-icon {
  border-radius: 100px;
  margin-left: 0px;
  max-width: 100%;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) and (max-device-width: 480px) {
  #maintenance-message {
    font-size: 25px;
  }
}
