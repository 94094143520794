.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.custom-modal {
  background-color: red;
}

.App-link {
  color: #61dafb;
}

figure {
  overflow: hidden;
  border-radius: 90px;
  background: white;
  height: 35px;
  margin: 0px;
  padding: 0px;
}

figure img {
  width: 40px;
  transition: 0.2s ease;
}

figure img:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.set_icon2 {
  display: none;
}
.set_icon {
  display: block;
  display: flex;
}
.threedots:after {
  content: "\2807";
  font-size: 20px;
  color: black;
}
.dropdown a:hover {
  text-decoration-line: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .set_icon {
    display: none;
  }
  .set_icon2 {
    display: block;
    display: flex;
    justify-content: end;
  }
}

.login-with-google-btn-new {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(https://developers.google.com/static/identity/images/g-logo.png);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  background-size: 22px 22px;
  height: 43px;
  text-align: left;
  width: 100%;
}

.login-with-google-btn-new:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-google-btn-new:active {
  background-color: #eeeeee;
}
