.extendButton {
    margin-top: 5px;
    background-color: green;
    min-width: 50px;
    border-radius: 10px;
    text-align: center;
    color: white;
    margin-right: 2px;
    display: block;
    margin-bottom: 4px;
    cursor: pointer;
}

.withDrawButton {
    background-color: red;
    min-width: 70px;
    border-radius: 10px;
    text-align: center;
    color: white;
    display: block;
    cursor: pointer;
}

.withDrawAllButton {
    background-color: #eba134;
    margin-top: 3px;
    margin-bottom: 0;
    width: 200px;
    border-radius: 10px;
    text-align: center;
    color: brown;
    display: block;
    cursor: pointer;
}

.extendAllButton {
    background-color: green;
    margin-top: 3px;
    margin-bottom: 0;
    width: 200px;
    border-radius: 10px;
    text-align: center;
    color: white;
    display: block;
    cursor: pointer;
}