* {
  font-family: "Solway", sans-serif;
}

html,
body {
  height: calc(100% - 3em);
  margin: 0;
  font-size: 14px;
  font-family: "Solway", sans-serif;
  /* word-break: break-all; */
  /* -webkit-text-size-adjust: 110%; */
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  height: 100%;
}

h3 {
  font-size: 1.1em;
  font-weight: 600;
}

h5 {
  font-size: 1em;
  font-weight: 600;
}

a {
  text-decoration: none !important;
}

input::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Solway,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.root {
  height: auto;
  margin: 0;
  padding: 0;
}

/* HELPERS */
.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mt-4 {
  margin-top: 4em;
}

.mt-5 {
  margin-top: 5em;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-6 {
  margin-bottom: 6em;
}

.pt-1 {
  padding-top: 1em;
}

.pt-2 {
  padding-top: 2em;
}

.pt-3 {
  padding-top: 3em;
}

.pt-4 {
  padding-top: 4em;
}

.pb-6 {
  padding-bottom: 6em;
}

.fa-17x {
  font-size: 1.7em;
}

/* TOP NAVIGATION BAR START */

.TopNavigationBar {
  color: white;
  background-color: #198474;
  font-size: 0.95em;
  font-weight: 600;
  justify-content: space-between;
  height: 3em;
  width: 100%;
  padding: 0px 10px;
  position: fixed;
  top: 0%;
  z-index: 991;
}

.TopNavigationBar a {
  color: white;
}

.TopNavigationBar h3 {
  margin: 0;
  font-weight: 500;
  color: white;
}

.afterTopNavigationBar {
  margin-top: 2.8em;
}

.afterTopNavigationBarWithoutFilters {
  margin-top: 1.8em;
}

.afterTopNavigationBarTwo {
  margin-top: 1.8em;
}

/* TOP NAVIGATION BAR END */

.NavigationBar-icon {
  display: block !important;
  text-align: center;
  margin-bottom: 2px;
  color: #3f505f;
  font-size: 1.05em;
}

.NavigationBar-icon span {
  display: block;
  font-weight: 600;
  font-size: 0.85em;
  /* font-variant-caps: all-petite-caps; */
}

/* .NavigationBar-home {
  display: block !important;
  text-align: center;
  margin-bottom: 6px;
  padding: 5px;
  border: 1.2px solid black;
  border-radius: 100%
} */

/* BOTTOM NAVIGATION BAR START */
.beforeBottomNavigationBar {
  margin-bottom: 52px !important;
}

.BottomNavigationBar {
  font-size: 0.95rem;
  box-shadow: 0px 10px 15px 0px #3f505f;
  background-color: #ffffff;
  height: 46px;
  width: 100%;
  padding: 15px 10px 10px 10px;
  position: fixed;
  bottom: 0%;
}

.BottomNavigationBar a {
  color: #3f505f;
}

.BottomNavigationBar a:hover {
  color: #3f505f;
}

/* BOTTOM NAVIGATION BAR END */

/* HERO SECTION START */

.Hero {
  margin-bottom: 1em;
  padding: 2em 2em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
  text-align: center;
  color: white;
}

.fullHeight {
  height: 100vh;
}

/* HERO SECTION END */

/* FORMS */

.RegistrationForm {
  padding: 1.5em;
  text-align: left;
}

.validation-error {
  color: red;
  text-align: left;
  font-size: 12px;
}

.warning {
  border-color: red !important;
}

.warning:focus {
  box-shadow: 0 0 0 0.2em rgba(255, 0, 0, 0.2);
}

.datepicker {
  position: fixed !important;
  z-index: 999 !important;
}

.facebook-button {
  height: 43px;
  min-width: 188px;
  width: 100%;
  background-color: rgb(66, 103, 178);
  display: inline-flex;
  align-items: center;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  padding: 0px 0px 0px 3px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-weight: 500;
  font-family: Solway, sans-serif;
}

.facebook-button i {
  padding: 10px 13px 10px 10px !important;
}

.google-button {
  width: 100%;
  min-width: 188px;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 1em !important;
}

.google-button div {
  margin-right: 0px !important;
}

.LoginButton {
  line-height: 2;
  height: 43px;
  min-width: 188px;
  width: 100%;
  border-radius: 2px;
  text-align: left;
}

.LoginButton i {
  margin-right: 9px;
}

.menu-active {
  color: #198474 !important;
}

.DividerWhite hr {
  border-color: white;
}

.LoadingOverlay {
  height: 100vh;
  position: static !important;
}

.Card {
  text-align: left;
  font-size: 0.9em;
  color: black;
}

.Card a {
  text-decoration: none !important;
}

/* COMMENT SECTION START */
.CommentThread {
  margin-top: 1em;
  text-align: left;
}

.Replies {
  margin-left: 20px;
}

/* COMMENT SECTION END */

/* SUBRATING BAR START */
.react-rater {
  font-size: 1.35rem;
  letter-spacing: 0.1rem;
}

.react-rater-star.is-active,
.react-rater-star.is-active-half:before {
  color: #ffa212d9 !important;
}

/* SUBRATING BAR END */

.ContactsCard {
  background-color: #fafafa2b;
  -webkit-box-shadow: 8px 8px 5px -8px rgba(196, 196, 196, 0.95);
  -moz-box-shadow: 8px 8px 5px -8px rgba(196, 196, 196, 0.95);
  box-shadow: 8px 8px 5px -8px rgba(196, 196, 196, 0.95);
}

.ContactCard {
  text-align: left;
  font-size: 0.9em;
  color: black;
}

.ContactCard img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
}

.ContactCard p,
h6 {
  margin-bottom: 0;
}

.searchBar {
  width: 100%;
  height: 30px;
  margin: 0 auto 0.75em auto;
  padding: 0.2em 1em 0.2em 1em;
  border-radius: 4px;
  border: 1px solid #cccccc;
}

.replyIcon {
  margin: 25px 15px 10px 9px;
  color: grey;
}

.reply-button {
  font-size: 0.75em;
  font-weight: 500;
  color: #4e4e4e !important;
}

.like-button {
  font-size: 0.75em;
  font-weight: 500;
  color: #4e4e4e !important;
}

.flag-button {
  font-size: 0.75em;
  font-weight: 500;
  margin-left: 1em;
  color: #4e4e4e !important;
}

.like-button:hover,
.reply-button,
.flag-button:hover,
.flag-button:hover {
  cursor: pointer;
}

.OfflineBar {
  width: 100%;
  text-align: center;
  position: relative;
  top: 3.5em;
  background-color: #999999;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
}

.OfflineBar i {
  color: yellow;
  font-size: 0.6em;
}

.ListItemName {
  font-size: 1.05em;
  font-weight: 600;
  margin-bottom: 0.1em;
  word-break: break-word !important;
}

.LoadingSpinner {
  width: 100px;
  margin: auto;
  animation: beat 0.7s infinite alternate ease-in-out, fade 3s normal ease-out;
  transform-origin: center;
}

/* Logo beat animation */
@keyframes beat {
  to {
    transform: scale(0.7);
  }
}

@keyframes fade {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.description {
  margin-top: 0.5em;
  text-align: left;
}

.description * {
  max-width: -webkit-fill-available;
  font-size: 1rem !important;
}

.rated-by-text {
  background-color: #2ac277;
  color: white;
  font-weight: bold;
  padding: 5px 0 5px 0;
  border-radius: 60px;
}

.comments-counter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  color: white;
  background-color: #6c757d;
  border-top-right-radius: 11px;
  border-bottom-left-radius: 5px;
}

.ra-status {
  position: absolute;
  bottom: 0;
  right: 0;
  /* border: 1px solid grey; */
  width: 20px;
  /* background-color: white; */
}

.comments-counter span {
  margin-right: 2px;
}

.socials {
  text-align: right;
}

.socials a {
  margin-right: 6px;
}

.location-icon {
  padding: 5px;
}

.roster-extend-withdraw-buttons {
  width: 100%;
  margin-top: 10px;
}

/* disable hover on mobile devices */
@media (pointer: coarse) {
  a:hover {
    color: #007bff;
  }

  .NavigationBar-icon:hover {
    color: #3f505f;
  }

  .TopNavigationBar a:hover {
    color: white;
  }

  .Card:hover {
    color: black;
  }

  .react-rater-star {
    color: #ccc !important;
  }
}

/* customize tabs */
.nav-tabs {
  justify-content: center;
}

.nav-item {
  padding: 6.5px;
  outline: none !important;
}

.bottom-navigation-item span {
  display: block;
  font-size: 0.7em;
  font-weight: 600;
  margin-top: -3px;
}

/* job list item */
.job-list-item {
  margin-bottom: 10px;
}
/* React autosuggest */
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  margin: 0 auto 0.75em auto;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  text-align: left;
  display: block;
  position: relative;
  width: calc(200% + 30px);
  margin-top: 30px;
  border: 1px solid #cccccc;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.full {
  width: 100% !important;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.found-counter {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 40px;
  font-size: 0.9rem;
}

.audio-video-icon {
  color: grey;
}

.audio-video-container {
  display: flex;
  align-items: center;
  height: 15px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: 0;
  left: 15;
  background-color: white;
  justify-content: space-evenly;
  border: 1px solid hsl(0, 0%, 80%);
}

.commentInput {
  display: block;
  text-align: left;
  padding: 2px 42px 2px 8px;
  border: 1px solid #adb5bd;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
  resize: both;
  margin: 8px 0;
}

.commentInput:focus {
  outline: none;
}

.commentInput[contenteditable]:empty::before {
  content: attr(data-content);
  color: #adb5bd;
}

.btn {
  text-decoration: none !important;
}

.replyBox {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 63px;
  left: 0;
  padding: 0 16px;
  height: auto;
  line-height: auto;
  width: 100%;
  color: black;
  background-color: #f5f5f5;
  border-top: 1px solid #adb5bd;
  text-align: left;
  font-size: 12px;
}

.userInput {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 16px;
  z-index: 2;
  width: 100%;
  background-color: white;
  min-height: 63px;
  border-top: 1px solid #adb5bd;
}

.problemMessageBox {
  width: fit-content;
  max-width: 230px;
  margin-top: 10px;
  padding: 4px 6px;
  border-radius: 10px;
  background-color: white;
  text-align: left;
  word-break: break-word;
}

.myMessage {
  align-self: flex-end;
  background-color: #8df24d69;
}

.yourMessage {
  align-self: flex-start;
}

.centeredBox {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: 13px;
  font-weight: 500;
}

/* Remove validated boostrap styles  */
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #dddddd !important;
  background-image: inherit !important;
  box-shadow: inherit !important;
  padding-right: 0 !important;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #212529 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: inherit !important;
  padding-right: 0 !important;
}

/* Remove number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.create-resume-button {
  text-align: center;
  border: 1px solid #bdbdbd;
  margin: 20px;
  height: 40px;
  line-height: 40px;
  color: #bdbdbd;
}

.Toastify__toast {
  min-height: auto;
  padding: 4px 8px;
}

/* this class makes the font awesome icon with circle */
.circle-icon {
  background: #f2f2f2;
  padding: 6.5px;
  border-radius: 50%;
  margin: 0;
}

ul.emojis {
  margin: 0;
  padding: 0;
  position: absolute;
  display: block;
  bottom: 16px;
  border: 1px solid rgba(11, 11, 11, 0.29);
  background-color: #fff;
  border-radius: 16px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

ul.emojis li {
  list-style-type: none;
  float: left;
  margin-right: 0.5em;
}

ul.emojis li:hover {
  font-size: 18px;
  z-index: 1;
}

.block {
  background-color: yellow;
  float: right;
  text-align: bottom;
}

.within {
  margin-top: -5.1rem;
  line-height: 4rem;
  height: 4rem;
  width: 99%;
  /* background-color:rgba(0, 0, 0, 0.4); */
}

.withoutScrollBar::-webkit-scrollbar {
  width: 0;
}

/** for notification bell icon **/
.notification-circle {
  background: red;
  color: white;
  padding: 6.5px;
  border-radius: 50%;
  margin: 0;
}

.newContentBadge {
  position: absolute;
  right: 20px;
  background-color: #f44336;
  color: white;
}

.navigationItemCountBadge {
  position: absolute;
  left: 50%;
  font-size: 0.75rem !important;
}

.custom-modal {
  width: 1000px;
}

.share-app-button {
  padding: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.24);
  color: white;
  border: 1px solid white;
  box-shadow: 1px 2px rgba(0, 0, 0, 0.24);
}

.share-app-button:hover {
  box-shadow: 1px 2px rgba(0, 0, 0, 0.7);
}

.share-app-button:active {
  background-color: #198474;
}

.share-app-button-container {
  border-radius: 50%;
  display: inline-block;
  padding: 0;
  margin: 0;
}

/* .social-share-buttons-container {
  padding: 3%;
  text-align: center;
  margin-top: -9px;
  background-color: #F8F6F0;
  border: 1px solid black;
  border-radius: 26px;
  position: absolute;
  box-shadow: 2px 3px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.share-app-button-container>button {
  background-color: rgba(0, 0, 0, 0.24);
  color: white;
  outline: 0;
  -moz-outline: 0;
} */

.ReactVirtualized__Grid__innerScrollContainer {
  overflow-y: scroll;
}

.list-view-container {
  margin-top: 100px;
}

@media only screen and (max-width: 1007px) {
  .list-view-container {
    margin-top: 120px;
  }
}

.profile-circle-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: contain;
}

.warning-message {
  font-weight: bold;
  background-color: #f1c40e;
  color: black;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
}

.ra-infomation {
  background-color: #3cb0b0;
  font-weight: bold;
  color: black;
  border-radius: 4px;
  padding: 10px;
}

.apply-button {
  background-color: #2ac277;
  border-radius: 60px;
  width: 100%;
  font-weight: bold;
}

.cancel-apply-button {
  background-color: white;
  color: black;
  border-radius: 60px;
  width: 100%;
}

.apply-button:hover,
.apply-button:active,
.apply-button:visited,
.apply-button:focus,
.apply-button:link {
  background-color: #42e0b8;
  border-radius: 60px;
  width: 100%;
  box-shadow: none;
}

.solid-icon {
  color: white; /* Change the color to your desired solid color */
}

.employer-information {
  background-color: whitesmoke;
  text-align: left;
  border: 1px solid gray;
  padding: 11px;
  border-radius: 11px;
}

.recruiter-information {
  text-align: center;
  border: 1px solid gray;
  padding: 11px;
  border-radius: 5px;
}

.hover-effect {
  border-radius: 12px;
}

.hover-effect:hover {
  border-color: #3b82f6; /* Blue border on hover for a modern touch */
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.00002);
}

.employer-information:hover,
.recruiter-information:hover {
  border-color: #3b82f6; /* Blue border on hover for a modern touch */
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.00002);
}

.job-emp-ra-profile {
  border: 1px solid gray;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.2); /* Adjust box-shadow for a softer effect */
}
.label-employer {
  font-size: 14px;
  color: #7d007d;
}

.job-label-header {
  font-weight: bold;
  margin-bottom: 20px;
}

.info-text {
  color: blue;
}

.button-modified {
  border-radius: 60px;
  cursor: pointer;
}

/* A distinct border color on hover */
.button-modified:hover {
  border-color: #3b82f6; /* Blue border on hover for a modern touch */
  box-shadow: 4px 8px 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.00002);
}

.attention {
  background-color: #f1ec17;
  padding: 11px;
  border: 1px solid black;
  border-radius: 11px;
  text-align: center;
  justify-content: center;
}

/* Style for the toast container */
.Toastify__toast-container {
  padding: 8px;
}

/* Style for individual toast messages */
.Toastify__toast {
  border-radius: 11px;
}

/* Assuming your modal has a class of .my-modal */
.required-fields-modal {
  z-index: 1050; /* Set a higher z-index value */
}

.glowing-background {
  width: 100%;
  background-color: #ffc007; /* Start with yellow background color */
  animation: glow 1s ease-in-out infinite alternate; /* Adjust timing as needed */
}

.button-width {
  min-width: 40%;
}

.phone-section {
  border: 1px solid grey;
  border-radius: 11px;
  padding: 9px;
  margin: 3px;
  margin-bottom: 9px;
  transition: all 0.3s; /* Smooth transitions for hover effects */
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #f3f4f6, #e5e7eb);
}

.full-width {
  width: 100%;
}

.cloud-border {
  border: 2px solid #7f8c8d; /* Cloud color */
  border-radius: 20px; /* Adjust this value to control the curvature of the cloud */
  padding: 10px; /* Padding to give some space inside the cloud */
  background-color: #fff; /* Background color inside the cloud */
  display: inline-block; /* Ensure the cloud respects the width of its content */
}

.center-content {
  text-align: center;
}

.sweet-green-bg {
  background-color: #2ac277;
}

.sweet-green-color {
  color: #2ac277;
}

.text-center {
  text-align: center;
}

.a-little-round {
  border-radius: 9px;
}

.full-screen-height {
  height: 100vh;
}

.input-with-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to your preference */
  transform: translateY(-50%);
}

.verified {
  color: #2dabf7;
}

.un-verified {
  color: #e70e07;
}

.js-profile-image {
  width: "110px";
  height: "110px";
  border-radius: "100%";
  object-fit: "cover";
  margin-top: "7px";
  border: "2px solid";
  border-color: blue;
}

.custom-progress-bar {
  height: 18px;
  border-radius: 60px;
}

.closed-job,
.expired-job {
  background-color: rgba(255, 0, 0, 0.03);
}

.gd-button {
  background-color: #198474;
  color: white;
  min-width: 30%;
}

hr {
  height: 0.3px;
  background-color: gray;
}

.gd-red {
  background-color: #f50102;
  color: white;
  font-weight: bold;
}

.gd-green {
  background-color: #198474;
  color: white;
  font-weight: bold;
}

.resume {
  color: darkgreen;
}
.job {
  color: black;
}
.applications {
  color: black;
}
.recruiter-listing {
  color: black;
}
.employer-listing {
  color: black;
}

.significant {
  color: #094099;
}

.operational {
  margin-bottom: 0;
  font-weight: 600;
  color: "green";
}

.temporarily-suspended {
  margin-bottom: 0;
  font-weight: 600;
  color: "orange";
}

.permanently-blacklisted {
  margin-bottom: 0;
  font-weight: 600;
  color: "red";
}

.license-is-not-extended {
  margin-bottom: 0;
  font-weight: 600;
  color: "darkorange";
}

.bold {
  font-weight: bold;
}

@keyframes glow {
  0% {
    background-color: yellow; /* Start color */
    box-shadow: 0 0 2px yellow, 0 0 5px yellow, 0 0 10px yellow; /* Glow effect */
  }
  100% {
    background-color: #ffc007; /* End color */
    box-shadow: 0 0 5px #ffc007, 0 0 10px #ffc007, 0 0 20px #ffc007; /* Glow effect */
  }
}

.english-news-message {
  font-size: 0.75rem;
}

.english-news-message_left .english-news-message_right {
  text-align: center;
}

.brown {
  color: brown;
}

.pleasant-green {
  color: #4caf50;
}

/* Bootstrap lg */
@media (min-width: 992px) {
  .english-news-message_left {
    text-align: right;
    padding-right: 0.25rem;
  }

  .english-news-message_right {
    text-align: left;
    padding-left: 0px;
  }
}

/* Width that fits one line at normal zoom */
@media (min-width: 1015px) {
  .english-news-message {
    font-size: inherit;
  }
}
