.like{
    color:#7c82ff;
    filter: grayscale(1); 
}

.love{
    color:#ff5858;
    filter: grayscale(1); 
}

.sad {
    color: #ffd93b;
    background: black;
    border-radius: 50%;
    outline-style: auto; 
    filter: grayscale(1); 
}

/* Add your hover styles for .like, .love, and .sad here */
.like:hover,
.love:hover,
.sad:hover {
  cursor: pointer;
  transform: scale(1.2); /* Increase the scale factor as needed */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add a shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transitions */
}

/* Define a keyframes animation for bouncing */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: scale(1.2);
    }
    40% {
      transform: scale(1.4);
    }
    60% {
      transform: scale(1.1);
    }
  }
  
  /* Apply styles for active state and add the bouncing animation */
  .like:active,
  .love:active,
  .sad:active {
    cursor: pointer;
    animation: bounce 0.6s ease-in-out; /* Apply the bouncing animation */
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease;
  }


/* styles.css */
.reaction-icons {
    display: flex;
}
  
.reaction-icons > div {
    text-align: right;
    padding: 0 5px;
}

/* Add grayscale effect for active icons */
.reaction-icons .active {
    filter: grayscale(0); /* Set the grayscale to 100% for active icons */
}