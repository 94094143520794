.step-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px; /* Adjust the width as needed */
    position: relative;
  }
  
  .step-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background-color: #f0f0f0; /* Default background color for inactive steps */
    color: #333;
  }
  
  .step-circle.active {
    background-color: #007bff; /* Customize the background color for active step */
    color: #fff;
    font-weight: bold;
  }
  