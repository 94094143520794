.bottom-modal {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.bottom-modal-content {
  width: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.bottom-modal-content.show {
  transform: translateY(0%);
}

.modal-comment-edit-header{
  background-color:#198474;
}